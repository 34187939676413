<template>
  <span class="spinner text-center">
    <clip-loader :loading="show" :size="size" style="display: inline"></clip-loader>
    <span v-if="show">{{message}}</span>
  </span>
</template>
<script>
import Spinner from './spinner.vue'

export default {
  extends: Spinner,
  props: {
    useModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
